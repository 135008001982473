import { isNumber, map, mapValues } from 'lodash-es';
import { MathRound2 } from '../util/MathRound2';
import type { currentStatType } from './currentStatType';
import { bigConstruct } from '../util/bigConstruct';

export function calcTotal(items: currentStatType['list']) {
	const stat = items.reduce(
		(prev, curr) => {
			prev.totalRent = prev.totalRent.plus(curr?.rent);

			prev.total = prev.total.plus(curr?.amount);

			prev.shouldTotal = prev.shouldTotal.plus(curr?.amount);

			if (curr?.deposit.toNumber() < 0) {
			}

			prev.totalDeposit = prev.totalDeposit.plus(curr?.deposit);

			prev.totalElectricCharge = prev.totalElectricCharge.plus(
				curr?.totalElectricCharge,
			);

			prev.electronUsed = prev.electronUsed.plus(curr?.totalElectricUsed);

			prev.totalHotWaterCharge = prev.totalHotWaterCharge.plus(
				curr?.totalHotWaterCharge,
			);

			prev.hotUsed = prev.hotUsed.plus(curr?.totalHotWaterUsed);

			prev.totalColdWaterCharge = prev.totalColdWaterCharge.plus(
				curr?.totalColdWaterCharge,
			);

			prev.coldUsed = prev.coldUsed.plus(curr?.totalColdWaterUsed);

			prev.totalWater = prev.totalWater
				.plus(curr?.totalHotWaterUsed)
				.plus(curr?.totalColdWaterUsed);

			return prev;
		},
		{
			total: bigConstruct(),
			totalRent: bigConstruct(),
			totalDeposit: bigConstruct(),
			electronUsed: bigConstruct(),
			totalElectricCharge: bigConstruct(),
			hotUsed: bigConstruct(),
			totalHotWaterCharge: bigConstruct(),
			coldUsed: bigConstruct(),
			totalColdWaterCharge: bigConstruct(),
			shouldTotal: bigConstruct(),
			totalWater: bigConstruct(),
		},
	);

	const roundData = mapValues(stat, (i, key) => {
		if (isNumber(i)) {
			return MathRound2(i);
		}

		return i;
	});

	return roundData;
}
